export const dataTable = [
  {
    loan_amount: "$1,000",
    interest_rate: "24.00%",
    laon_term: "12 months",
    fee: "3.00%",
    fee_cost: "$30.00",
    repayment: "$94.56",
    apr: "29.82%",
    total_repayments: "$1,134.72",
    total_cost: "$164.72",
  },
  {
    loan_amount: "$2,000",
    interest_rate: "19.00%",
    laon_term: "24 months",
    fee: "5.00%",
    fee_cost: "$100.00",
    repayment: "$100.82",
    apr: "24.12%",
    total_repayments: "$2,419.68",
    total_cost: "$519.68",
  },
  {
    loan_amount: "$5,000",
    interest_rate: "13.00%",
    laon_term: "48 months",
    fee: "8.00%",
    fee_cost: "$400.00",
    repayment: "$131.67",
    apr: "18.23%",
    total_repayments: "$6,320.12",
    total_cost: "$1,720.12",
  },
  {
    loan_amount: "$10,000",
    interest_rate: "7.90%",
    laon_term: "60 months",
    fee: "10.00%",
    fee_cost: "$100.00",
    repayment: "$202.28	",
    apr: "9.20%",
    total_repayments: "$12,136.80",
    total_cost: "$3,136.80",
  },
];
