import React from "react";

const GetStart = () => {
  return (
    <div>
      <div id="_lg_form_"></div>
    </div>
  );
};

export default GetStart;
